import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ReactComponent as BackArrow } from "../../assets/svg/left-back.svg";
import { ReactComponent as UpArrow } from "../../assets/svg/up-sharp.svg";
import { ReactComponent as masterCard } from "../../assets/svg/master-card.svg";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import PaypalIcon from "../../assets/png/paypal.png";
import { useNavigate } from "react-router-dom";

const Payment1 = () => {
  const navigate = useNavigate();
  const amount = JSON.parse(localStorage.getItem("total_amount")) || [];
  const finalAmount = Array.isArray(amount)
    ? parseFloat(
        amount
          .reduce((total, item) => total + (item.price * item.quantity || 0), 0)
          .toFixed(2)
      )
    : 0;
  const taxes = parseFloat(JSON.parse(localStorage.getItem("taxes"))) || 0;
  const shipping =
    parseFloat(JSON.parse(localStorage.getItem("shipping"))) || 0;
  const isAbove720 = useMediaQuery("(min-width:900px)");

  // State for each form field
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const element = document.querySelector(".css-1r2d01j-MuiGrid-root");
    if (element) {
      element.classList.remove("css-1r2d01j-MuiGrid-root");
    }
  }, []);
  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 4) {
      value = value.replace(/(\d{4})(?=\d)/g, "$1 ");
    }
    setCardNumber(value);
  };

  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");

    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    if (value.length > 2) {
      value = value.replace(/^(\d{2})(\d{2})$/, "$1 / $2");
    }

    setExpiry(value);
  };
  const handleSubmit = async (e) => {
    console.log("button clicked");

    e.preventDefault();

    if (!cardNumber || !expiry || !cvv || !nameOnCard || !country) {
      alert("Please fill in all fields.");
      return;
    }

    setLoading(true);

    try {
      const body = JSON.stringify({
        cardNumber: cardNumber ? cardNumber.replace(/\s+/g, "") : "",
        expiry: expiry ? expiry.replace(/\s+/g, "") : "",
        cvv: cvv ? cvv.replace(/\s+/g, "") : "",
        nameOnCard: nameOnCard || "",
        country: country || "",
      });
      const response = await fetch("https://your-api-endpoint.com/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Payment successful:", result);
        alert("Payment was successful!");
        navigate("/success");
      } else {
        throw new Error("Payment failed");
      }
    } catch (error) {
      console.error("Error during payment:", error);
      alert("There was an error processing your payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // const [paymentMethod, setPaymentMethod] = useState("paypal");
  // const handlePaymentMethodChange = (method) => {
  //   setPaymentMethod(method);
  // };
  return (
    <Box
      className="font-hebo"
      sx={{
        maxWidth: "1159px",
        mx: "auto",
        p: isAbove720 ? "0" : "20px",
      }}
      // sx={{
      //   maxWidth: "1159px",
      //   mx: "auto",
      //   p: isAbove720 ? "0" : "20px",
      // }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: "16px",
          bgcolor: "#ebf6ff",
          display: "flex",
          flexDirection: isAbove720 ? "row" : "column",
          marginTop: isAbove720 ? 0 : "30px",
          marginBottom: isAbove720 ? 0 : "20px",
          boxShadow: "none",
        }}
      >
        <Box
          width={isAbove720 ? "43.2%" : "100%"}
          sx={{ padding: isAbove720 ? "115px 94px 115px 94px" : "0" }}
        >
          <Typography
            sx={{
              fontSize: isAbove720 ? "20px" : "22px",
              lineHeight: isAbove720 ? "20px" : "26px",
              fontWeight: "700",
              fontFamily: isAbove720 ? "DM Sans" : "DM Sans",
              marginBottom: isAbove720 ? "9px" : "20px",
              color: isAbove720 ? "#4B54A2" : "#0C2A52",
              px: isAbove720 ? 0 : "14px",
            }}
            marginTop={isAbove720 ? 0 : "30px"}
          >
            Shipping Information
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: isAbove720 ? "Heebo" : "Heebo",
              lineHeight: "25.6px",
              marginBottom: "20px",
              color: isAbove720 ? "#666666" : "#666666",
              padding: isAbove720 ? "0" : "0 15px",
            }}
          >
            XYZ Communications Pvt Ltd.
          </Typography>
          <Typography
            sx={{
              fontWeight: "700",
              fontFamily: isAbove720 ? "DM Sans" : "DM Sans",
              fontSize: isAbove720 ? "20px" : "22px",
              px: isAbove720 ? 0 : "14px",
              color: isAbove720 ? "#4B54A2" : "#0C2A52",
              lineHeight: isAbove720 ? "14px" : "26.4px",
              marginBottom: isAbove720 ? "20px" : "20px",
            }}
          >
            Order Summary
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: isAbove720 ? "Heebo" : "Heebo",
              lineHeight: isAbove720 ? "22px" : "22px",
              marginBottom: isAbove720 ? "20px" : "20px",
              color: isAbove720 ? "#666666" : "#666666",
              padding: isAbove720 ? "0" : "0 24px 0 19px",
            }}
          >
            You will be billed immediately for your once off and set up costs.
            Your monthly costs will be charged at the end of your first billing
            cycle.
          </Typography>
          <Divider sx={{ marginBottom: "20px", borderColor: "#7B83EB" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: isAbove720 ? "0" : "0 19px",
            }}
          >
            <Typography
              variant="body1"
              style={{ color: "#666666", lineHeight: "22.4px" }}
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Heebo",
                marginBottom: "6px",
              }}
            >
              Sub Total:
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "#5E718B",
                lineHeight: "22.4px",
              }}
              sx={{
                fontWeight: isAbove720 ? "700" : "400",
                fontSize: "14px",
                fontFamily: isAbove720 ? "DM Sans" : "Heebo",
              }}
            >
              ${finalAmount}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "Heebo",
              px: isAbove720 ? 0 : "19px",
              marginBottom: "6px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: "#666666",
                lineHeight: "22.4px",
                fontFamily: isAbove720 ? "DM Sans" : "Heebo",
                fontSize: "14px",
              }}
            >
              Taxes:
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "#5E718B",
                lineHeight: "22.4px",
              }}
              sx={{
                fontWeight: isAbove720 ? "700" : "400",
                fontSize: "14px",
                fontFamily: isAbove720 ? "DM Sans" : "Heebo",
              }}
            >
              ${taxes}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              padding: isAbove720 ? "0" : "0 19px",
            }}
          >
            <Typography
              variant="body1"
              style={{ color: "#666666", lineHeight: "22.4px" }}
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Heebo",
              }}
            >
              Shipping & Handling:
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "#5E718B",
                lineHeight: "22.4px",
              }}
              sx={{
                fontWeight: isAbove720 ? "700" : "400",
                fontSize: "14px",
                fontFamily: isAbove720 ? "DM Sans" : "Heebo",
              }}
            >
              ${shipping}
            </Typography>
          </Box>

          <Divider sx={{ marginBottom: "20px", borderColor: "#7B83EB" }} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: isAbove720 ? 0 : "20px",
            }}
          >
            <Typography
              variant="h6"
              padding={isAbove720 ? "0" : "0 15px"}
              style={{ color: "#666666", lineHeight: "22.4px" }}
              sx={{
                fontSize: "14px",
                fontFamily: "Heebo",
                fontWeight: "400",
              }}
            >
              Grand Total:
            </Typography>
            <Typography
              variant="h6"
              padding={isAbove720 ? "0" : "0 15px"}
              style={{
                color: "#5E718B",
                lineHeight: "22.4px",
              }}
              sx={{
                fontSize: isAbove720 ? "20px" : "16px",
                fontFamily: "Heebo",
                lineHeight: "25.6px",
                fontWeight: "700",
              }}
            >
              ${shipping + taxes + finalAmount}
            </Typography>
          </Box>
          {!isAbove720 && (
            <Divider sx={{ marginBottom: "20px", borderColor: "#7B83EB" }} />
          )}
        </Box>
        {isAbove720 && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: "#7B83EB",
              height: "42vh",
              alignSelf: "center",
            }}
          />
        )}
        <Box
          width={isAbove720 ? "56%" : "100%"}
          sx={{
            padding: isAbove720 ? "85px 125px 85px 103px" : "0 20px 20px 20px",
          }}
        >
          {/* Payment Method Buttons */}
          <Box
            sx={{
              width: "100%",
              border: "1px solid #7B83EB",
              borderBottomWidth: "1px",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "9.45px 0",
            }}
          >
            <img
              src={PaypalIcon}
              alt="PaypalIcon"
              style={{
                width: "77.2px",
                height: "18.9px",
                objectFit: "cover",
              }}
            />
          </Box>
          {/* OR Divider */}
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ margin: isAbove720 ? "20px 0 13px 0" : "19px 0 20px 0" }}
          >
            <Divider sx={{ flex: 1, borderColor: "#7B83EB" }} />

            <Typography
              color="textSecondary"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "14px",
                fontFamily: "Heebo",
                verticalAlign: "middle",
                color: "#0C2A52",
              }}
            >
              OR
            </Typography>

            <Divider sx={{ flex: 1, borderColor: "#7B83EB" }} />
          </Stack>
          {/* paypay icon */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: 2,
              padding: isAbove720 ? "0" : "0",
              paddingBottom: isAbove720 ? "0" : "0",
              marginBottom: isAbove720 ? "10px" : "20px",
            }}
          >
            <RadioGroup>
              <FormControlLabel
                value="Credit Card / Debit Card"
                control={<Radio sx={{ color: "#7B83EB" }} />}
                label="Credit Card / Debit Card"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  paddingLeft: "9px",
                  gap: "6px",
                  fontFamily: "Heebo, sans-serif",
                  color: "#5E718B",
                }}
                classes={{
                  label: "inside-card-label",
                }}
              />
            </RadioGroup>
          </Box>
          {/* credit  card*/}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap", // Allow wrapping on small screens
              gap: "10px", // Small gap between items
              justifyContent: "space-between", // Distribute space evenly
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                height: "56px",
              }}
            >
              <Typography
                variant="body1"
                className="form-label"
                sx={{ fontSize: "10px", color: "#666666" }}
              >
                Card Number
              </Typography>
              <TextField
                required
                placeholder="1111 2222 3333 4444"
                variant="outlined"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#FFFFFF",
                    "& fieldset": {
                      borderColor: "#7B83EB",
                      borderWidth: "1px",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#7B83EB",
                    },
                    "& input": {
                      padding: "8.5px 10px !important",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <masterCard style={{ width: "24px", height: "24px" }} />{" "}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {/* Expiry - 50% width on larger screens */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "48%",
                height: "56px",
              }}
            >
              <Typography
                variant="body1"
                className="form-label"
                sx={{ color: "#666666" }}
              >
                Expiry
              </Typography>
              <TextField
                required
                placeholder="MM / YY"
                variant="outlined"
                fullWidth
                value={expiry}
                onChange={(e) => setExpiry(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#FFFFFF",
                    "& fieldset": {
                      borderColor: "#7B83EB",
                      borderWidth: "1px",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#7B83EB",
                    },
                    "& input::placeholder": {
                      fontSize: "14px",
                      fontFamily: "Heebo",
                    },
                    "& input": {
                      padding: "8.5px 10px !important",
                    },
                  },
                }}
              />
            </Box>

            {/* CVV - 50% width on larger screens */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "48%",
                height: "56px",
                color: "#666666",
              }}
            >
              <Typography
                variant="body1"
                className="form-label"
                sx={{ color: "#666666" }}
              >
                CVV
              </Typography>
              <TextField
                required
                placeholder="123"
                variant="outlined"
                fullWidth
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                sx={{
                  marginBottom: "10px",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#FFFFFF",
                    "& fieldset": {
                      borderColor: "#7B83EB",
                      borderWidth: "1px",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#7B83EB",
                    },
                    "& input::placeholder": {
                      fontSize: "14px",
                      fontFamily: "Heebo",
                    },
                    "& input": {
                      padding: "8.5px 10px !important",
                    },
                  },
                }}
              />
            </Box>
            {/* Name on the Card - Full width */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                height: "56px",
                color: "#666666",
              }}
            >
              <Typography
                variant="body1"
                className="form-label"
                sx={{ fontSize: "10px" }}
              >
                Name on the card
              </Typography>
              <TextField
                required
                placeholder="John Doe"
                variant="outlined"
                fullWidth
                value={nameOnCard}
                onChange={(e) => setNameOnCard(e.target.value)}
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#FFFFFF",
                    "& fieldset": {
                      borderColor: "#7B83EB",
                      borderWidth: "1px",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#7B83EB",
                    },
                    "& input": {
                      padding: "8.5px 10px !important",
                    },
                  },
                }}
              />
            </Box>

            {/* Country - Full width */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                height: "56px",
              }}
            >
              <Typography
                variant="body1"
                className="form-label"
                sx={{ fontSize: "10px" ,color: "#666666" }}
              >
                Country
              </Typography>

              <Select
                required
                defaultValue="USA"
                displayEmpty
                placeholder="Country"
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  backgroundColor: "#FFFFFF",
                  "& .MuiOutlinedInput-root": {
                    borderColor: "#7B83EB",
                    borderWidth: "1px",
                  },
                  "& fieldset": {
                    borderColor: "#7B83EB",
                    color: "#666666",
                    borderWidth: "1px",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#7B83EB",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#7B83EB",
                  },
                  "& .MuiSelect-select": {
                    padding: "8.5px 10px !important",
                  },
                }}
              >
                <MenuItem value="USA" sx={{ fontFamily: 'Heebo' }}>USA</MenuItem>
                <MenuItem value="Canada" sx={{ fontFamily: 'Heebo' }}>Canada</MenuItem>
                <MenuItem value="Mexico" sx={{ fontFamily: 'Heebo' }}>Mexico</MenuItem>
              </Select>
            </Box>
          </Box>
          {/* Back and Pay Now Buttons */}
          {isAbove720 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mt: isAbove720 ? "20px" : "",
                gap: "9px",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => navigate("/")}
                sx={{
                  borderRadius: "14.5px !important",
                  fontSize: "12px",
                  lineHeight: "19px",
                  padding: "4.84px 9px 4.84px 9px",
                  fontFamily: "Heebo",
                  fontWeight: "700",
                  textTransform: "none",
                }}
              >
                Back
                <UpArrow />
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  borderRadius: "14.5px !important",
                  fontSize: "12px",
                  lineHeight: "19px",
                  padding: "4px 15px",
                  fontFamily: "Heebo",
                  fontWeight: "700",
                  textTransform: "none",
                  width: isAbove720 ? "115px" : "",
                }}
              >
                {loading ? "Processing..." : "Pay Now"}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>

      {!isAbove720 && (
        <Paper
          elevation={3}
          sx={{
            borderRadius: "16px",
            bgcolor: "#ebf6ff",
            boxShadow: "none",
          }}
        >
          <Grid
            item
            xs={6}
            className="calculation-box-checkout"
            sx={{
              border: { sm: "1px solid #7B83EB80", xs: "none" },
              borderRadius: "10px",
              padding: "20px",
              backgroundColor: "#ebf6ff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h6"
                fontWeight="00"
                lineHeight="22.4px"
                color="#666666"
                fontFamily="Heebo"
                sx={{ fontSize: "14px" }}
              >
                Grand Total: ${finalAmount}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mr: 1,
                  borderRadius: "14.5px",
                  padding: "10px",
                  maxWidth: "83px",
                  textTransform: "none",
                  fontFamily: "Heebo",
                  backgroundColor: "#EBF6FF",
                  color: "#7B83EB",
                  border: "1px solid #7B83EB",
                  boxShadow: "none",
                }}
              >
                <UpArrow />
                <span>Back</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "14.5px",
                  padding: "10px 0",
                  backgroundColor: "#7B83EB",
                  "&:hover": { backgroundColor: "#5a65e6" },
                  lineHeight: "22.4px",
                  textAlign: "center",
                  minWidth: "108px",
                  fontFamily: "Heebo",
                }}
                class="checkout-button-class"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Pay Now"}
              </Button>
            </Box>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default Payment1;
