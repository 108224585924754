import { Box } from "@mui/material";
import { Elements, LinkAuthenticationElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect } from "react";
import BgImage from "../../assets/jpg/bg.jpg";
import { AppContext } from "../../store/store";
import CheckoutForm from "./CheckoutForm";
import Payment from "../../pages/Signup/Paymet1"

const stripePromise = loadStripe(
  "pk_test_51IxkYnE2SdKoR9r8gM6fOtgnWa5d18f8hTeyKmyWNzvWdrLE2rZnTAtKjE9yEmVlxOUQ7kWenaOwHVb3nV7esVYF00TKBQkY56"
);
const StripeCheckout = () => {
  // const { conversionRates } = useContext(AppContext);
  // const signupdetails = JSON.parse(localStorage.getItem("fabio_details"));

  // const currency = localStorage.getItem("currency");
  // const payAmount = Math.round(
  //   (
  //     parseInt(localStorage.getItem("amount")) * conversionRates[currency]
  //   ).toFixed(2) * 100
  // );
  // useEffect(() => {
  //   localStorage.setItem("is_checkout", "true");
  // }, []);
  // const options = {
  //   mode: "payment",
  //   amount: 1,
  //   currency: currency,
  //   // automatic_payment_methods: { enabled: true },
  //   // Fully customizable with appearance API.
  //   appearance: {
  //     /*...*/
  //   },
  // };
  // const optionsAddress = {
  //   mode: "billing",
  //   autocomplete: {
  //     mode: "automatic",
  //   },
  //   defaultValues: {
  //     name: signupdetails.first_name + signupdetails.last_name,
  //     address: {
  //       line1: signupdetailsAddress.signupdetails.address,
  //       line2: signupdetailsAddress.signupdetails.address,
  //       city: signupdetailsAddress.signupdetails.city,
  //       state: signupdetailsAddress.signupdetails.region,
  //       postal_code: signupdetailsAddress.signupdetails.post_code,
  //       country: "India",
  //     },
  //   },
  //   contacts: [
  //     {
  //       name: signupdetails.first_name + signupdetails.last_name,
  //       address: {
  //         line1: signupdetailsAddress.signupdetails.address,
  //         line2: signupdetailsAddress.signupdetails.address,
  //         city: signupdetailsAddress.signupdetails.city,
  //         state: signupdetailsAddress.signupdetails.region,
  //         postal_code: signupdetailsAddress.signupdetails.post_code,
  //         country: "india",
  //       },
  //     },
  //   ],
  // };
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        backgroundImage: `url(${BgImage})`,
      }}
      sx={{
        height: {
          xs: "auto", 
          sm: "auto", 
          md: "100vh",
        },
      }}
    >
        {/* <Box display={"none"}> */}
          {/* <LinkAuthenticationElement
            options={{ defaultValues: { email: signupdetails.email } }}
          /> */}
          {/* <AddressElement
            options={optionsAddress}
            mode="shipping"
          ></AddressElement> */}
        {/* </Box> */}
        {/* <CheckoutForm /> */}
        {/* <h1>Thank you for your purchase</h1> */}
        <Payment />
    </Box>
  );
};

export default StripeCheckout;
