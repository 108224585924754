import React, { useState } from "react";
import { ReactComponent as BackArrow } from "../../assets/svg/left-back.svg";
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import headset from "../../assets/png/headset.png";
import ivr from "../../assets/png/ivr.png";
import meeting from "../../assets/png/meeting.png";
import PersonIcons from "../../assets/png/Vector.png";
import emptyCart from "../../assets/png/emptyCart.png";

import { useNavigate } from "react-router-dom";

const CartPopup = ({
  signupdetails,
  handleIncrementOneOff,
  handleDecrementOneOff,
  handleIncrementMonthly,
  handleDecrementMonthly,
  handleRemoveOneOff,
  handleRemoveMonthly,
  activeStep,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const isAbove720 = useMediaQuery("(min-width:720px)");
  const isBetween1000and1150 = useMediaQuery("(min-width: 900px) and (max-width: 1150px)");
  // Dynamically construct cartItems based on signupdetails
  const constructCartItems = () => {
    const oneOffItems = [];
    const monthlyItems = [];


    // Map the one-off items from signupdetails
    if (signupdetails.handset) {
      oneOffItems.push({
        name: "Handset",
        description: "Lorem Ipsum",
        price: 30,
        quantity: signupdetails.handset,
        image: headset,
      });
    }
    if (signupdetails.handset1) {
      oneOffItems.push({
        name: "Handset 1",
        description: "Lorem Ipsum",
        price: 30,
        quantity: signupdetails.handset1,
        image: headset,
      });
    }
    if (signupdetails.total_ivr_flow) {
      oneOffItems.push({
        name: "IVR Call Flow Setup",
        description: "Lorem Ipsum",
        price: 20,
        quantity: signupdetails.total_ivr_flow,
        image: ivr,
      });
    }
    if (signupdetails.meeting_device) {
      oneOffItems.push({
        name: "Meeting Room Devices",
        description: "Lorem Ipsum",
        price: 100,
        quantity: signupdetails.meeting_device,
        image: meeting,
      });
    }

    // Map the monthly items from signupdetails
    if (signupdetails.basic_call_user) {
      monthlyItems.push({
        name: "Basic call recording",
        description: "Lorem Ipsum",
        price: 180,
        icon: <PersonIcon />,
        quantity: signupdetails.basic_call_user,
      });
    }
    if (signupdetails.premium_call_user) {
      monthlyItems.push({
        name: "Premium call recording",
        description: "Lorem Ipsum",
        price: 180,
        icon: <PersonIcon />,
        quantity: signupdetails.premium_call_user,
      });
    }
    const data = signupdetails.location.reduce((sum, item) => sum + item.number_of_users, 0);
    const data1 = signupdetails.location.reduce((sum, item) => sum + item.numbers_to_port, 0);
    const data2 = signupdetails.location.reduce((sum, item) => sum + item.additional_numbers, 0);
    
    if (signupdetails.location) {
      if (data > 0) {
        monthlyItems.push({
          name: "number_of_users",
          description: "Lorem Ipsum",
          price: 0,
          icon: <PersonIcon />,
          quantity: data,
        });
      }
      if (data1 > 0) {
        monthlyItems.push({
          name: "numbers_to_port",
          description: "Lorem Ipsum",
          price: 0,
          icon: <PersonIcon />,
          quantity: data1,
        });
      }
      if (data2 > 0) {
        monthlyItems.push({
          name: "additional_numbers",
          description: "Lorem Ipsum",
          price: 0,
          icon: <PersonIcon />,
          quantity: data2,
        });
      }
    }

    return { oneOffItems, monthlyItems };
  };

  // Get the dynamic cart items
  const { oneOffItems, monthlyItems } = constructCartItems();

  if (!isOpen) return null;

  return (
    <>
      {oneOffItems.length + monthlyItems.length > 0 ? (
        <Box
          className={!isAbove720 ? "" : "custom-popup-box"}
          sx={{
            position: "fixed",
            top: !isAbove720
              ? activeStep === 0
                ? "80%"
                : activeStep === 1
                ? "43%"
                : activeStep === 2
                ? "38%"
                : activeStep === 3
                ? "52%"
                : "50%"
              : "50%",

            left: !isAbove720
              ? activeStep === 0
                ? "50%"
                : activeStep === 1
                ? "50%"
                : activeStep === 2
                ? "50%"
                : activeStep === 3
                ? "50%"
                : "35%"
              : "35%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            borderRadius: "16px",
            // padding: isAbove720 ? "20px 45px" : "20px 15px",
            width: "100%",
            zIndex: 1000,
            border: "1px solid #8828FF",
            background: "#F0F1FF",
            gap: "20px",
            boxshadow: "none",
            padding: isAbove720 ?  "49px 64px 20px 66px" : "20px 15px",
            maxWidth: "861px",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: isAbove720 ? "20px" : "20px",
              // padding: isAbove720 ? "0px" : "0 10px",
            }}
          >
            <Typography
              variant="h6"
              fontFamily="DM Sans"
              fontWeight="700"
              fontSize={isAbove720 ? "55px" : "26px"}
              marginTop={isAbove720 ? "0px" : "0px"}
              lineHeight={isAbove720 ? "66px" : "31px"} 
              color="#0C2A52"
            >
              Your Cart
            </Typography>
            <IconButton onClick={() => setIsOpen(false)} sx={{'&:hover': {backgroundColor: "transparent"}}}>
              {/* , color: "#0C2A52 */}
              <CloseIcon
                sx={{
                  fontSize: isAbove720 ? 30 : 25,
                  border: "1px solid #7B83EB",
                  borderRadius: "50%",
                  color: "#7B83EB",
                }}
              />
            </IconButton>
          </Box>

          {/* Cart Items Section */}
          <Box sx={{display: "flex",
          flexDirection: isAbove720 ? "row" : "column", gap: "20px"}}>
            {/* One-off Cost Items */}
            <Box
              item
              width={isAbove720 ? "53.2%" : "100%"}
              sx={{
                border: isAbove720 ? "1px solid #7B83EB80" : "none",
                borderRadius: "5px",
                padding: isAbove720 ? "20px 15px" : "0px",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={isAbove720 ? "700" : "500"}
                fontSize={isAbove720 ? "14px" : "16px"}
                fontFamily={isAbove720 ? "DM Sans" : "Heebo"}
                color="#0C2A52"
                paddingLeft={isAbove720 ? "1px" : "1px"}
                paddingBottom={isAbove720 ? "20px" : "1px"}
              >
                One-off Cost Items 
              </Typography>
              {/* {isAbove720 && <Divider sx={{ mb: 2 }} />} */}
              <Box
                sx={{
                  height: "48vh",
                  maxHeight: "48vh", // Set maximum height
                  overflowY: "auto", // Enable vertical scrolling
                }}
              >
                {oneOffItems.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: isAbove720 ? "none" : "1px solid #7B83EB80",
                      marginBottom: isAbove720 ? "none" : "10px",
                      borderRadius: "5px",
                      padding: "20px",
                    }}
                    className="cart-item"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{
                          width: isAbove720 ? "40px" : "61px",
                          height: isAbove720 ? "40px" : "53px",
                        }}
                      />
                      <Box sx={{ flex: 1, ml: !isAbove720 ? 2 : isBetween1000and1150 ? "0.5" : 2, mt: isAbove720 ? "8px" : "0px" }}>
                        <Typography
                          fontWeight="700"
                          className="item-name"
                          lineHeight={"10px"}
                          marginBottom={isAbove720 ? "15px" : "10px"}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          className="item-desc"
                          color="textSecondary"
                        >
                          {item.description}
                        </Typography>
                        {isAbove720 ? (
                          ""
                        ) : (
                          <Typography
                            fontWeight="700"
                            sx={{ fontSize: "14px", fontFamily: "Heebo", marginTop: "30px", lineHeight: "10px" }}
                          >
                            ${item.price * item.quantity}
                          </Typography>
                        )}
                      </Box>
                      {isAbove720 ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          border="1px solid #7B83EB"
                          borderRadius="4px"
                          width="fit-content"
                          marginRight="10px"
                        >
                          <Button
                            variant="text"
                            onClick={() => handleDecrementOneOff(index, item)}
                            sx={{
                              lineHeight: "16px",
                              padding: 0,
                              maxWidth: "17px",
                              minWidth: "17px",
                              borderRadius: "0",
                              borderRight: "1px solid #7B83EB",
                            }}
                          >
                            -
                          </Button>
                          <Typography
                            variant="body1"
                            sx={{
                              lineHeight: "16px",
                              padding: 0,
                              maxWidth: "17px",
                              minWidth: "17px",
                              fontSize: "8px",
                              fontWeight: "bold",
                              textAlign: "center",
                              borderRight: "1px solid #7B83EB",
                            }}
                          >
                            {item.quantity}
                          </Typography>
                          <Button
                            variant="text"
                            onClick={() => handleIncrementOneOff(index)}
                            sx={{
                              lineHeight: "16px",
                              padding: 0,
                              maxWidth: "17px",
                              minWidth: "17px",
                              borderRadius: "0",
                            }}
                          >
                            +
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                      {isAbove720 ? (
                        <>
                          <Typography
                            fontWeight="700"
                            sx={{
                              fontSize: "14px",
                              fontFamily: "Heebo",
                              marginLeft: isBetween1000and1150 ? "0px" : "29px",
                            }}
                          >
                            ${item.price * item.quantity}
                          </Typography>
                          <IconButton onClick={() => handleRemoveOneOff(index)} sx={{'&:hover': {backgroundColor: "transparent"}}}>
                            <CloseIcon
                              sx={{
                                fontSize: 14,
                                color: "#7B83EB",
                                border: "1px solid #7B83EB",
                                marginLeft:  isBetween1000and1150 ? "8px" : "21px",
                                borderRadius: "5px",
                              }}
                            />
                          </IconButton>
                        </>
                      ) : (
                        ""
                      )}

                      {isAbove720 ? (
                        ""
                      ) : (
                        <Box
                          sx={{
                            flex: 0,
                            textAlign: "end",
                          }}
                        >
                          <IconButton
                            sx={{ marginBottom: "38px", padding: "0", '&:hover': {backgroundColor: "transparent"}}}
                            onClick={() => handleRemoveOneOff(index)}
                          >
                            <CloseIcon
                              className="button-icon"
                              paddingLeft="0px"
                              sx={{
                                fontSize: 14,
                                color: "#7B83EB",
                                border: "1px solid #7B83EB",
                                borderRadius: "5px",
                              }}
                            />
                          </IconButton>
                          <Box
                            display="flex"
                            alignItems="center"
                            border="1px solid #7B83EB"
                            borderRadius="4px"
                            width="fit-content"
                          >
                            <Button
                              variant="text"
                              onClick={() => handleDecrementOneOff(index)}
                              sx={{
                                lineHeight: "16px",
                                padding: 0,
                                maxWidth: "17px",
                                minWidth: "17px",
                                borderRadius: "0",
                                borderRight: "1px solid #7B83EB",
                              }}
                            >
                              -
                            </Button>
                            <Typography
                              variant="body1"
                              sx={{
                                lineHeight: "16px",
                                padding: 0,
                                maxWidth: "17px",
                                minWidth: "17px",
                                fontSize: "8px",
                                fontWeight: "700",
                                textAlign: "center",
                                borderRight: "1px solid #7B83EB",
                              }}
                            >
                              {item.quantity}
                            </Typography>
                            <Button
                              variant="text"
                              onClick={() => handleIncrementOneOff(index)}
                              sx={{
                                lineHeight: "16px",
                                padding: 0,
                                maxWidth: "17px",
                                minWidth: "17px",
                                borderRadius: "0",
                              }}
                            >
                              +
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    {isAbove720 && index < oneOffItems.length - 1 && (
                      <Divider sx={{ my: 2 }} />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
            {/* Monthly Cost Items */}
            <Box
              item
              width={isAbove720 ? "42%" : "100%"}
              sx={{
                border: isAbove720 ? "1px solid #7B83EB80" : "none",
                borderRadius: "5px",
                padding: "20px",
                // paddingLeft: isAbove720 ? "1px" : "1px",
              }}
            >
              {isAbove720 ? (
                <Typography
                  variant="h6"
                  fontWeight={isAbove720 ? "700" : "500"}
                  fontSize={isAbove720 ? "16px" : "16px"}
                  fontFamily={isAbove720 ? "DM Sans" : "Heebo"}
                  color="#0C2A52"
                  gutterBottom
                >
                  Monthly Cost Items 
                </Typography>
              ) : (
                monthlyItems.length > 0 && (
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    fontSize="16px"
                    color="#0C2A52"
                    gutterBottom
                    fontFamily={isAbove720 ? "DM Sans" : "Heebo"}
                  >
                    Monthly Cost Items
                  </Typography>
                )
              )}
              {/* {isAbove720 && <Divider sx={{ mb: 2 }} />} */}
              <Box
                sx={{
                  height: "48vh",
                  maxHeight: "48vh", 
                  overflowY: "auto", 
                }}
              >
                {monthlyItems.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: isAbove720 ? "none" : "1px solid #7B83EB80",
                      marginBottom: isAbove720 ? "none" : "10px",
                      borderRadius: "5px",
                      padding: "20px",
                    }}
                    className="cart-item"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                          <img
                            src={PersonIcons}
                            alt={item.name}
                            style={{ width: isAbove720 ?  "40px" : "41px", height: isAbove720 ?  "40px" : "41px" }}
                          />
                        </Box>
                        <Box sx={{ ml: isAbove720? "" : 5, textAlign: "left" }}>
                          <Typography
                            fontWeight="700"
                            className="item-name"
                            marginBottom={"12px"}
                            sx={{ lineHeight: "13px !important" }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className="item-desc"
                            marginBottom={"14px"}
                          >
                            {item.description}
                          </Typography>
                          {isAbove720 ? (
                            <Box
                              display="flex"
                              alignItems="center"
                              border="1px solid #7B83EB"
                              borderRadius="4px"
                              width="fit-content"
                            >
                              <Button
                                variant="text"
                                onClick={() => handleDecrementMonthly(index)}
                                sx={{
                                  lineHeight: "16px",
                                  padding: 0,
                                  maxWidth: "17px",
                                  minWidth: "17px",
                                  borderRadius: "0",
                                  borderRight: "1px solid #7B83EB",
                                }}
                              >
                                -
                              </Button>
                              <Typography
                                variant="body1"
                                sx={{
                                  lineHeight: "16px",
                                  padding: 0,
                                  maxWidth: "17px",
                                  minWidth: "17px",
                                  fontSize: "8px",
                                  fontWeight: "700",
                                  textAlign: "center",
                                  borderRight: "1px solid #7B83EB",
                                }}
                              >
                                {item.quantity}
                              </Typography>
                              <Button
                                variant="text"
                                onClick={() => handleIncrementMonthly(index)}
                                sx={{
                                  lineHeight: "16px",
                                  padding: 0,
                                  maxWidth: "17px",
                                  minWidth: "17px",
                                  borderRadius: "0",
                                }}
                              >
                                +
                              </Button>
                            </Box>
                          ) : (
                            <Typography
                              fontWeight="700"
                              sx={{ fontSize: "14px", fontFamily: "Heebo" }}
                            >
                              ${item.price * item.quantity}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      {isAbove720 ? (
                        ""
                      ) : (
                        <Box sx={{ textAlign: "end" }}>
                          <IconButton
                            sx={{ marginBottom: "38px", padding: "0" ,'&:hover': {backgroundColor: "transparent"}}}
                            onClick={() => handleRemoveMonthly(index)}
                          >
                            <CloseIcon
                              sx={{
                                fontSize: 15,
                                color: "#7B83EB",
                                border: "1px solid #7B83EB",
                                borderRadius: "5px",
                                marginLeft: "21px",
                              }}
                            />
                          </IconButton>
                          <Box
                            display="flex"
                            alignItems="center"
                            border="1px solid #7B83EB"
                            borderRadius="4px"
                            width="fit-content"
                            marginTop="5px"
                          >
                            <Button
                              variant="text"
                              onClick={() => handleDecrementMonthly(index)}
                              sx={{
                                lineHeight: "16px",
                                padding: 0,
                                maxWidth: "17px",
                                minWidth: "17px",
                                borderRadius: "0",
                                borderRight: "1px solid #7B83EB",
                              }}
                            >
                              -
                            </Button>
                            <Typography
                              variant="body1"
                              sx={{
                                lineHeight: "16px",
                                padding: 0,
                                maxWidth: "17px",
                                minWidth: "17px",
                                fontSize: "8px",
                                fontWeight: "700",
                                textAlign: "center",
                                borderRight: "1px solid #7B83EB",
                              }}
                            >
                              {item.quantity}
                            </Typography>
                            <Button
                              variant="text"
                              onClick={() => handleIncrementMonthly(index)}
                              sx={{
                                lineHeight: "16px",
                                padding: 0,
                                maxWidth: "17px",
                                minWidth: "17px",
                                borderRadius: "0",
                              }}
                            >
                              +
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {isAbove720 ? (
                        <>
                          <Box sx={{ ml: 2, textAlign: "center" }}>
                            <IconButton
                              onClick={() => handleRemoveMonthly(index)}
                              sx={{'&:hover': { backgroundColor: "transparent"}}}
                            >
                              <CloseIcon
                                sx={{
                                  fontSize: 14,
                                  marginLeft: "21px",
                                  color: "#7B83EB",
                                  marginBottom: "32px",
                                  border: "1px solid #7B83EB",
                                  borderRadius: "5px",
                                }}
                              />
                            </IconButton>
                            <Typography
                              fontWeight="700"
                              sx={{ fontSize: "14px", fontFamily: "Heebo" }}
                            >
                              ${item.price * item.quantity}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                    {isAbove720 && index < monthlyItems.length - 1 && (
                      <Divider sx={{ my: 2 }} />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          {/* Footer Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "24px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsOpen(false)}
              sx={{
                width: "10%",
                flexShrink: 0,
                fontSize: "14px",
                borderRadius: "14.5px",
                maxWidth: isAbove720 ? "62px" : "83px",
                textTransform: "none",
                lineHeight: "19.2px",
                fontWeight: "700",
                fontFamily: "Heebo"
              }}
            >
              <BackArrow />
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/checkout")}
              sx={{
                // width: "30%",
                flexShrink: 0,
                fontSize: isAbove720 ? "12px" : "14px",
                borderRadius: "14.5px",
                maxWidth: "162px",
                minWidth: "162px",
                textTransform: "none",
                fontWeight: "700",
                lineHeight: isAbove720 ? "15px" : "42px",
                padding: isAbove720 ? "0" : "0",
                fontFamily: "Heebo"
              }}
            >
              Proceed to Checkout
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              borderRadius: "16px",
              width: "100%",
              height: "749px",
              zIndex: 1000,
              border: "2px solid #8828FF",
              padding: isAbove720 ? "228px auto 224px auto": "20px 15px",
              background: "#F0F1FF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              maxWidth: "861px",
            }}
          >
            <div>
            <img
              src={emptyCart}
              alt="Cart Icon"
              style={{
                width: "126px",
                height: "126px",
                marginBottom: "20px",
              }}
            />
            <Typography
              fontWeight="700"
              className="empty-cart"
              sx={{
                marginBottom: "20px", 
                fontSize: "18px",
              }}
            >
              Hey There, Your Cart is Empty!
            </Typography>
            <Button
              className="empty-back-button"
              color="primary"
              onClick={() => setIsOpen(false)}
              sx={{
                width: "50%",
                maxWidth: "212px",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "22.4px",
                textTransform: "none",
                fontFamily:"Heebo",
                cursor: "pointer",
                borderRadius: "14.5px",
              }}
            >
              Start Shopping
            </Button>
            </div>
          </Box>
        </>
      )}
    </>
  );
};

export default CartPopup;
